@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #111827;
  margin: 0;
  padding: 0;
}

@layer components{

  .btn-primary {
    @apply bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded;
  }
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #2d3748; /* Darker background */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4a5568; /* Gray background */
  border-radius: 10px;
  border: 3px solid #2d3748; /* Darker border for thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #718096; /* Lighter gray when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #4a5568 #2d3748;
}

