@tailwind base;
@tailwind components;
@tailwind utilities;

.interview-container {
    height: 65vh; /* Adjust as necessary for your layout */
    display: flex;
    flex-direction: column;
}

.interview-container > .flex-1 {
    flex-grow: 1;
    overflow-y: auto;
}
